<template>
    <div>
        <div class="w3-bar w3-top w3-theme-l4" style="z-index: 4">
            <button id="wjudul" class="w3-bar-item w3-theme-d5 w3-btn w3-hover-none w3-hover-text-black w3-hide-small w3-hide-medium" style="width: 240px">
                <!-- <img src="assets/images/icon-pms.png" class="" style="height: 25px"/> &nbsp; <small>[{{namaapp}}]</small> -->
                <i src="assets/images/icon-pms.png"></i> &nbsp; <b> BRANTAS TUNTAS</b> <small>[{{namaapp}}]</small>
            </button>
            <button id="wopen" class="w3-bar-item w3-btn w3-hover-none w3-hover-text-black w3-hide-small w3-hide-medium" v-on:click="openMenu(); vmenu = vmenu == 'a' ? 'b' : 'a';">
            </button>
            <div class="">
                <div class="w3-dropdown-hover" style="position: fixed; right: 50px">
                    <!-- <button class="w3-btn w3-theme-l4 w3-border-left" v-on:click="$router.push({name: 'home'})"><i class="fa fa-home"></i></button> -->
                    <button v-on:click="loadProfile()" class="w3-btn w3-theme-l4 w3-border-left" style="width: 200px" >
                        <i class="fa fa-user-circle-o"></i> {{ username }}<span class="caret"></span>
                    </button>
                    <div class="w3-dropdown-content w3-bar-block w3-theme-d5" style="width: 200px" id="menu-drop1" ></div>
                </div>
                <button class="w3-btn w3-theme-l4 w3-border-left w3-right" title="Log Out" v-on:click="logoutSystem()" style="width: 50px" >
                    <i class="fa fa-sign-out"></i>
                </button>
            </div>
            <button class="w3-bar-item w3-button w3-hide-large w3-hover-none w3-hover-text-light-grey" v-on:click="openMenu()" >
                <i class="fa fa-bars"></i> &nbsp;Menu
            </button>
            <span class="w3-bar-item w3-right">Logo</span>
        </div>
        <!-- <nav class="w3-sidebar w3-collapse w3-animate-left w3-theme-d5" style="z-index: 3; width: 240px; top: 30px;overflow: auto; direction: rtl; text-align: left;" id="mySidebar" > -->
        <nav v-if="showMenu" class="w3-sidebar w3-collapse w3-animate-left w3-white w3-theme-d4" style="z-index:3;width: 240px; top: 30px;overflow: auto; direction: rtl; text-align: left;" id="mySidebar">
            <br />
            <div class="w3-container w3-row">
                <div class="w3-col s4">
                    <img src="assets/images/logo_person.jpg" class="w3-circle w3-margin-right" style="width: 45px; height: 50px" />
                </div>
                <div class="w3-col s8 w3-bar">
                    <span >Welcome, <strong>{{ username }}</strong></span ><br />
                </div>
            </div>
            <hr style="margin-top:13px;"/>
            <div class="w3-bar-block" id="menuid" style="margin-bottom: 40px;">
				<div v-if="recMenus.length==0">
					<DeLoading :show="true" title="Loading menu ..."></DeLoading>
				</div>
                <template v-else v-for="mn in recMenus" >
                    <template v-if="mn.mn_type=='P'">
                        <router-link :key="mn.mn_url" :to="mn.mn_url"  style="padding: 2px 0px 10px 8px; text-transform: uppercase;" class="w3-bar-item w3-button " :class="(menuinduk.toUpperCase()== mn.mn_url.toUpperCase()) ? 'menu-aktif w3-theme-l1' : ''" >
                            {{mn.mn_name}} <i :class="mn.mn_icon"></i>
                        </router-link>
                    </template>
                    <template v-else>
                        <div style="padding: 2px 0px 0px 8px; text-transform: uppercase;" :key="mn.mn_name">
                            {{mn.mn_name}} <i :class="mn.mn_icon"></i>
                            <div v-for="mnd in mn.detail" :key="mnd.mn_name" style="padding: 2px 0px 0px 5px">
                                <router-link :to="mnd.mn_url" class="w3-bar-item w3-button"
                                style="padding: 2px 0px 0px 20px !important;" :class="(menuinduk.toUpperCase()==mnd.mn_url.toUpperCase()) ? 'menu-aktif  w3-theme-l1' : ''">
                                    {{mnd.mn_name}} <i :class="mnd.mn_icon"></i>
                                </router-link>
                            </div>
                        </div>
                    </template>
                </template>
            </div>
        </nav>
        <div class="w3-overlay w3-hide-large w3-animate-opacity" v-on:click="closeMenu()" style="cursor:pointer" title="close side menu" id="myOverlay"></div>
        <div class="w3-main w3-theme-l1" style="margin-top: 39px; margin-left: 240px" id="main" >
            <div class="w3-theme-l3 scrollmenu">
                <router-link v-show="rt.show" v-for="(rt, index) in items" :to="rt.path" class="w3-button w3-border-left" :key="index" :class="rt.name.toUpperCase()==(menuinduk=='/'?'DASHBOARD':menuinduk)?(rt.tabClass + ' w3-text-white'):'w3-white'">
                    <i class="fa" :class="rt.icon"></i> {{rt.title}}
                    <i class="fa fa-close w3-small" v-show="(items.length>1 && rt.closable)" @click="closeTabRoute(index)"></i>
                </router-link>
            </div>
            <router-view v-if="items.length>0" />
            <div v-else>
                <DeLoading :show="true" msg="Inisial menu ..."></DeLoading>
            </div>
        </div>
    </div>
</template>
<script>
export default {
    name: "Dashboard",
    components: {},
    data() {
        return {
            username: localStorage.ufname,
            menuinduk : localStorage.menuinduk,
            appVersion: localStorage.appVersion,
            namaapp: "",
            recMenus: [],
            showMenu: (localStorage.showMenu==undefined ? true : (localStorage.showMenu=="true"?true:false)),
            showAnimasiMenu: false,
            items: []
        };
    },
    methods: {
        openMenu(){
            let mySidebar = document.getElementById("mySidebar");
            let overlayBg = document.getElementById("myOverlay");
            if (mySidebar.style.display === "block!important" || mySidebar.style.display === "block"){
                mySidebar.style.display = "none";
                overlayBg.style.display = "none";
                mySidebar.style.zIndex = "0";
            } else {
                mySidebar.style.display = "block";
                overlayBg.style.display = "block";
                mySidebar.style.zIndex = "3";
            }
        },
        closeMenu(){
            let mySidebar = document.getElementById("mySidebar");
            mySidebar.style.display = "none";
            mySidebar.style.zIndex = "0";
            let overlayBg = document.getElementById("myOverlay");
            overlayBg.style.display = "none";
        },
        getIcon(mn) {
            if (mn.mn_icon != "" && mn.mn_icon != null && mn.mn_icon != "-") {
                return "fa " + mn.mn_icon;
            }
            return "fa fa-book";
        },
        getMenu(mn) {
            if (mn.mn_type == "F") {
                return "loadanak/" + mn.mn_id;
            }
            return "" + mn.mn_url;
        },
        loadUrlmenu(mn, beda) {
            if (beda == 0) {
                // w3_open();
            }
        },
        logoutSystem() {
            const self = this;
            // eslint-disable-next-line no-undef
            swal(
                {
                    title: "Log Out",
                    text: "Yakin Ingin Log Out ..!",
                    type: "warning",
                    showCancelButton: true,
                    confirmButtonColor: "#DD6B55",
                    confirmButtonText: "Ya, Log Out",
                    cancelButtonText: "Tidak",
                    closeOnConfirm: true,
                    closeOnCancel: true
                },
                function(isConfirm) {
                    if (isConfirm) {
                        localStorage.clear();
                        self.$router.push("/login");
                        // const remote = require('electron').remote;
                        // let window = remote.getCurrentWindow();
                        // window.close();
                    }
                }
            );
        },
        async loadMenu(){
            const self = this;
            self.$store.state.modAksesmenu.recAkses= {};
            self.recMenus = [];
            self.apiPost("/brantas/gurumenu/search",{
                operator: "AND",
                kondisi: "=;=;=",
                field: "guru.guru_code;mn_up;guru_menu_status",
                fieldvalue: localStorage.userid + ";0;T",
                limit: 10,
                offset: 0,
                order: "mn_urut"
            }, async function(res){
                if(res.ttlrec>0){
                    for (let index = 0; index < res.rec.length; index++) {
                        const val = res.rec[index];
                        if (val["mn_url"]!="-"){
                            self.$store.state.modAksesmenu.recAkses[val["mn_url"].toUpperCase()] = {
                                "add": val["guru_menu_add"],"edit":val["guru_menu_edit"],"delete":val["guru_menu_delete"],"print":val["guru_menu_print"]
                            };
                        }
                        let resd = await self.apiAsync("/brantas/gurumenu/search",{
                            operator: "AND",
                            kondisi: "=;=;=",
                            field: "mn_up;guru.guru_code;guru_menu_status",
                            fieldvalue: val.mn_id +";"+localStorage.userid +";T" ,
                            limit: 20,
                            offset: 0,
                            order: "mn_urut"
                        });
                        if (resd.ttlrec>0){
                            for (let indexd = 0; indexd < resd.rec.length; indexd++) {
                                const element = resd.rec[indexd];
                                if(element["mn_url"]!="-"){
                                    self.$store.state.modAksesmenu.recAkses[element["mn_url"].toUpperCase()] = {
                                        "add": element["guru_menu_add"],"edit":element["guru_menu_edit"],"delete":element["guru_menu_delete"],"print":element["guru_menu_print"]
                                    };
                                }
                            }
                            res.rec[index]["detail"] = resd.rec;
                        }
                        
                    }
                    self.recMenus = res.rec;
                    localStorage.recAkses = JSON.stringify(self.$store.state.modAksesmenu.recAkses);
                    
                    if (self.items.length>0){
                        let ketemu = false;
                        self.items.map(function(val){
                            if(val.path==self.$route.path){
                                ketemu = true;
                            }
                        });
                        if (!ketemu){
                            self.items.push({
                                name: self.$route.name, 
                                title: self.$route.meta.title, 
                                tips: self.$route.meta.tips, 
                                icon: self.$route.meta.icon, 
                                closable: self.$route.meta.closable, 
                                tabClass: self.$route.meta.tabClass==undefined?"w3-theme-l1":self.$route.meta.tabClass, 
                                path: self.$route.path,
                                show: true
                            });
                        }
                    }else{
                        self.items.push({
                            name: self.$route.name, 
                            title: self.$route.meta.title, 
                            tips: self.$route.meta.tips, 
                            icon: self.$route.meta.icon, 
                            closable: self.$route.meta.closable, 
                            tabClass: self.$route.meta.tabClass==undefined?"w3-theme-l1":self.$route.meta.tabClass, 
                            path: self.$route.path,
                            show: true
                        });
                    }
                }
            });
        },
        closeTabRoute(index){
            const self = this;
            self.items.splice(index,1);
            if (index!=0){
                let menu = self.items[self.items.length -1];
                self.$router.push("/").catch(error => {
                    // eslint-disable-next-line no-console
                    console.info(error.message);
                });
                setTimeout(function(){
                    self.$router.push(menu.path).catch(error => {
                        // eslint-disable-next-line no-console
                        console.info(error.message);
                    });
                },100);
            }
        }
    },
    watch: {
        "$route": function(){
            const self = this;
            // console.log(this.$route);
            this.menuinduk = this.$route.name.toUpperCase();
            if(this.menuinduk=="DASHBOARD"){
                this.menuinduk = "/";
            }
            // eslint-disable-next-line no-console
            console.log(this.menuinduk);
            // self.menuinduk = self.$route.path.toUpperCase();
            if (self.items.length>0){
                let ketemu = false;
                self.items.map(function(val){
                    if(val.path==self.$route.path){
                        ketemu = true;
                    }
                });
                if (!ketemu){
                    self.items.push({
                        name: self.$route.name, 
                        title: self.$route.meta.title, 
                        tips: self.$route.meta.tips, 
                        icon: self.$route.meta.icon, 
                        closable: self.$route.meta.closable, 
                        tabClass: self.$route.meta.tabClass==undefined?"w3-theme-l1":self.$route.meta.tabClass, 
                        path: self.$route.path,
                        show: true
                    });
                }
            }else{
                self.items.push({
                    name: self.$route.name, 
                    title: self.$route.meta.title, 
                    tips: self.$route.meta.tips, 
                    icon: self.$route.meta.icon, 
                    closable: self.$route.meta.closable, 
                    tabClass: self.$route.meta.tabClass==undefined?"w3-theme-l1":self.$route.meta.tabClass, 
                    path: self.$route.path,
                    show: true
                });
            }
        }
    },
    mounted() {
        this.menuinduk = this.$route.name.toUpperCase();
        if(this.menuinduk=="DASHBOARD"){
            this.menuinduk = "/";
        }
        this.namaapp = this.appName;
        this.loadMenu();
    }
};
</script>

<style scoped>
.d-flex {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.main {
  margin-left: 15%;
  padding: 0;
}
.side {
  width: 15%;
}
.menu-aktif{
	border-radius: 50px 0px 0px 50px;
	margin-left: 5px;
	/*padding-top: 20px;*/
	height: 24px;
    font-weight: bold;
}

.menu-induk {
	padding: 5px!important;
	text-align: center;
	cursor: pointer;
}

.menu-anak{
	border-radius: 50px 50px 50px 50px;
	/* height: 25px; */
	/* width: 25px; */
	/* background-color: rgb(74, 155, 128); */
}

div.scrollmenu {
    background-color: rgb(248, 244, 244);
    overflow: auto;
    white-space: nowrap;
    /* height: 40px; */
}
::-webkit-scrollbar {
    height: 5px;
    overflow: visible;
    width: 5px;
    color: inherit;
}

div.scrollmenu a {
    display: inline-block!important;
    color: grey;
    text-align: center;
    padding: 10px;
    /* text-decoration: none; */
}

div.scrollmenu a:hover {
    background-color: #777;
}
</style>
